import React from 'react'

import './footer.css'

class Footer extends React.Component {
    constructor(props){
        super(props)
    }

    render() {        
        return (
            <div className="FooterGroup">
                <p className="Text">Copyright &copy; Zhe ZHANG | doubleZ, Peking University.</p>
                <p className="Text">All Right Reserved.</p>

                {this.props.showRecord ? (
                    <p className="Text">
                        <img src="https://pic3.zhimg.com/80/v2-d0289dc0a46fc5b15b3363ffa78cf6c7.png"></img>
                        <a href="https://beian.miit.gov.cn/" target="_blank">吉ICP备20002957号</a>
                    </p>
                ) : (
                    <p className="Text"></p>
                )}
                
            </div>           
        )
    }
}

export default Footer