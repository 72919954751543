import React from 'react'
import { Link } from 'gatsby'
import './navigation.css'

import avatar from '../../images/avatar/dbz-avatar.jpg'

class Navigation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        hasScrolled: false,
        hasBlurred: false,
        lang: 'zh'
    }
  }

  async componentDidMount() {
      this.handleScroll();
      window.addEventListener("scroll", this.handleScroll);

      let that = this;
      window.addEventListener('focus', function() {
        // location.reload();
        that.setState({ hasBlurred: false });
      });
      window.addEventListener('blur', function() {
        that.setState({ hasBlurred: true });
      });
  }

  handleScroll = (event) => {
      const scrollTop = window.pageYOffset;
      if(scrollTop > 50) {
          this.setState({ hasScrolled: true });
      } else {
          this.setState({ hasScrolled: false });
      }
  }

  handleBtnClick = (event) => {
    // console.log("click...")
    // if(this.state.lang=="zh"){
    //   this.setState({ lang: "en" })
    // } else {
    //   this.setState({ lang: "zh" })
    // }
    // console.log(this.state.lang)
  }

  render() {
      return (
          <div id="Navigation" className={this.state.hasScrolled ? 'Navigation NavigationScrolled' : 'Navigation'}>
            <div className="NavigationGroup">
              <a href="#"><img className={this.state.hasBlurred ? 'blur' : ''} src={avatar} /></a>
              <ul>
                <li><a href="#life-trace" title="人生是个不断贴标签的过程">{this.state.lang=="zh" ? '人生轨迹' : 'Experience'}</a></li>
                <li><a href="#life-award" title="让他着迷的是结果之外的一切">但行耕耘</a></li>
                <li><a href="#better-study" title="将来的你回感谢现在拼命的自己">好好学习</a></li>
                <li><a href="#better-live" title="生活其实很简单生出来活下去">好好生活</a></li>
              </ul>
              {/* <button onClick={this.handleBtnClick}><Link to="#for-the-time" title="时间比我能告诉你的更多">给时间</Link></button> */}
              <button><Link to="/research" title="Homepage for research in English.">Research(EN)</Link></button>
            </div>
          </div>
      )
  }
}

export default Navigation